<template>
  <a-form @submit="handleSubmit" :form="form" class="form">
    <a-card class="card" title="基础信息" :bordered="false">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="名称">
            <a-input
              placeholder="检测中心名称"
              v-model="formData.centerName" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="所属区县">
            <a-input
                    placeholder="所属区县"
                    v-model="formData.adName"
                    disabled/>
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item
            label="计划下达年份">
            <a-date-picker
              name="buildTime"
              mode="year"
              placeholder="请选择年份"
              format="YYYY"
              v-model="state.year"
              style="width: 100%"
              :value="state.year"
              :open="state.isopen"
              @focus="changeopen"/>
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="是否竣工">
            <a-select placeholder="请选择是或否" v-model="formData.compAcc">
              <a-select-option
                :value="item.value"
                :selected="item.value == formData.compAcc"
                v-for="item in trueList"
                :key="item.value"
              >{{ item.desc }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="经度">
            <a-input
              placeholder="请输入经度"
              v-model="formData.longitude" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="纬度">
            <a-input
              placeholder="请输入纬度"
              v-model="formData.latitude" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item
            label="开始运营时间">
            <a-date-picker style="width: 100%" @change="startDateChange" v-model="formData.startDate"/>
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="总投资（万）">
            <a-input
              placeholder="请输入总投资"
              v-model="formData.totInv" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="中央下达资金">
            <a-input
              placeholder="请输入中央下达资金"
              v-model="formData.cenProvFund" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="省级资金">
            <a-input
              placeholder="请输入省级资金"
              v-model="formData.provInvFund" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="市县资金">
            <a-input
              placeholder="请输入市县资金"
              v-model="formData.cntycInvFund" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="用于仪器设备资金">
            <a-input
              placeholder="用于仪器设备资金"
              v-model="formData.equFund" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="建设形式">
            <a-select placeholder="请选择是或否" v-model="formData.buiWay">
              <a-select-option
                :value="item.value"
                :selected="item.value == formData.buiWay"
                v-for="item in wayList"
                :key="item.value"
              >{{ item.desc }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="依托单位名称">
            <a-input
              placeholder="依托单位名称"
              v-model="formData.depName" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="化验室总面积">
            <a-input
              placeholder="化验室总面积"
              v-model="formData.cheTotAcr" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-card>
    <!-- 第二部分 -->
    <a-card class="card" title="机构、人员与运行经费" :bordered="false" style="margin-top:15px;">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="是否成立专门管理机构">
            <a-select placeholder="请选择是和否" v-model="formData.admOrgan">
              <a-select-option
                :value="item.value"
                :selected="item.value == formData.admOrgan"
                v-for="item in trueList"
                :key="item.value"
              >{{ item.desc }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="在编人员数">
            <a-input
              placeholder="在编人员数"
              v-model="formData.nowStaffName" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item
            label="取得相关资格证书人员数">
            <a-input
              placeholder="取得相关资格证书人员数"
              v-model="formData.quaStaffName" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="是否正常运行">
            <a-select placeholder="请选择是和否" v-model="formData.norOperYn">
              <a-select-option
                :value="item.value"
                :selected="item.value == formData.norOperYn"
                v-for="item in trueList"
                :key="item.value"
              >{{ item.desc }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="运行经费来源">
            <a-select placeholder="请选择" v-model="formData.fromRun">
              <a-select-option
                :value="item.value"
                :selected="item.value == formData.fromRun"
                v-for="item in fromList"
                :key="item.value"
              >{{ item.desc }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="年实际落实运行经费">
            <a-input
              placeholder="年实际落实运行经费"
              v-model="formData.runFundYear" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item
            label="水质站联系人">
            <a-input
              placeholder="水质站联系人"
              v-model="formData.countyWaterName" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="联系方式">
            <a-input
              placeholder="联系方式"
              v-model="formData.countyWaterPhone" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-card>
    <!-- 第三部分 -->
    <a-card class="card" title="检测指标" :bordered="false" style="margin-top:15px;">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="常规指标数量">
            <a-input
              placeholder="常规指标数量"
              v-model="formData.detBas" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="常规指标名称">
            <a-input
              placeholder="常规指标名称"
              v-model="formData.tarName" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item
            label="非常规指标数量">
            <a-input
              placeholder="非常规指标数量"
              v-model="formData.notDetBas" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="非常规指标名称">
            <a-input
              placeholder="非常规指标名称"
              v-model="formData.notTarName" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="是否有CMA资质">
            <a-select placeholder="请选择是和否" v-model="formData.qualCations">
              <a-select-option
                :value="item.value"
                :selected="item.value == formData.qualCations"
                v-for="item in trueList"
                :key="item.value"
              >{{ item.desc }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="其他常规指标">
            <a-input
              placeholder="其他常规指标"
              v-model="formData.tarNameOther" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-card>
    <!-- 第四部分 -->
    <a-card class="card" title="工程信息" :bordered="false" style="margin-top:15px;">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="检测集中供水工程处数">
            <a-input
              placeholder="检测集中供水工程处数"
              v-model="formData.cheProPla" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item label="检测集中供水工程处比例">
            <a-input
              placeholder="检测集中供水工程处比例"
              v-model="formData.plaTotPor" />
          </a-form-item>
        </a-col>
        <a-col :lg="6" :md="12" :sm="24">
          <a-form-item
            label="占全县供水总人口比例">
            <a-input
              placeholder="占全县供水总人口比例"
              v-model="formData.popTotPor" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-card>
    <a-form-item v-if="showSubmit" style="margin-top:15px;">
      <a-button type="primary" style="margin-right:10px;" @click="addCenter">提交</a-button>
      <a-button style="margin-right:10px;" @click="goList">返回</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import moment from 'moment'
import { addCenterInfo, getCenterInfo, updateCenterInfo } from '@/api/basic/center'

export default {
  name: 'RepositoryForm',
  props: {
    showSubmit: {
      type: Boolean,
      default: false
    },
    centerId: {
      type: String,
      default: '1'
    }
  },
  data () {
    return {
      form: this.$form.createForm(this),
      trueList: [
        {
          value: 0,
          desc: '否'
        },
        {
          value: 1,
          desc: '是'
        }
      ],
      wayList: [
        {
          value: 1,
          desc: '独立建设'
        },
        {
          value: 2,
          desc: '依托建设'
        }
      ],
      fromList: [
        {
          value: 1,
          desc: '水费'
        },
        {
          value: 2,
          desc: '水费和政府财政补贴'
        },
        {
          value: 3,
          desc: '其他'
        }
      ],
      // 查询参数
      areaParam: {
        id: 0
      },
      areaParam2: {
        id: 0
      },
      areaParam3: {
        id: 0
      },
      state: {
        year: moment(new Date()),
        isopen: false
      },
      // 返回数据
      areaListOne: [],
      areaListTwo: [],
      areaListThree: [],
      formData: {}
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    // 接口请求
    getDetail () {
      if (this.centerId === '0') {
        this.formData = this.formDataDefault
        this.getArea(this.areaParam)
      } else {
        getCenterInfo({ id: this.centerId }).then(response => {
          this.formData = response
          // this.formData.type = response.data.list[0].value
          console.log(this.formData)
          if (this.formData.planYear > 0) {
            this.state.year = this.formData.planYear.toString()
          }
        })
      }
    },
    addCenter () {
      if (this.centerId === '0') {
        addCenterInfo(this.formData).then(response => {
          this.$message.info('检测中心信息添加成功')
          this.$router.push({
            path: '/base/center'
          })
        })
      } else {
        updateCenterInfo(this.formData).then(response => {
          this.$message.info('检测中心信息更新成功')
        })
      }
    },
    // 行政区划
    changeopen () {
      this.state.isopen = true
    },
    startDateChange (value) {
      this.formData.startDate = value.format('YYYY-MM-DD')
    },
    moment,
    // 操作
    goList () {
      this.$router.back()
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$notification['error']({
            message: 'Received values of form:',
            description: values
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
