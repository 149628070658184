<template>
  <a-card :bordered="false">
    <repository-form ref="repository" :showSubmit="true" :centerId="this.$route.query.id" />
  </a-card>
</template>

<script>
import RepositoryForm from './centerEdit/RepositoryForm'

export default {
  name: 'AdvancedForm',
  components: {
    RepositoryForm
  },
  created () {

  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

